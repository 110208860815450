@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: Raleway;
    src: url('./assets/fonts/CerebriSans-Regular.woff2') format('woff');
}

@font-face {
    font-family: Raleway-Bold;
    src: url('./assets/fonts/LibreFranklin-VariableFont_wght.ttf') format('truetype');
}

@layer utilities {
    .animation-delay-2000 {
        animation-delay: 2s;
    }
    .animation-delay-4000 {
        animation-delay: 4s;
    }
}


body{
    background: rgb(223,222,255);
    background: linear-gradient(90deg, rgba(223,222,255,1) 0%, rgba(254,254,254,1) 100%);
    width: 100%
}


@layer base{
    body{
        @apply font-[Raleway]
    }
}

.custom-h1 {
    @apply text-center lg:text-[48px] md:text-[40px] text-[27px] font-bold text-gray-800 w-full;
}

.custom-h1-noncenter{
    @apply lg:text-[40px] md:text-[26px] text-[27px] font-bold text-gray-800 ;
}

.custom-h2{
    @apply lg:text-[34px] md:text-[24px] text-[22px] font-bold text-gray-800 ;
}

.custom-p{
    @apply text-center md:text-[15px] text-[13px] text-gray-800 w-full;
}

.custom-p-bold{
    @apply md:text-2xl text-[17px] font-bold text-gray-800 w-full;
}

.custom-p-card{
    @apply md:text-xl text-[19px] text-gray-800 w-full;
}

.custom-button{
    @apply md:inline-block bg-[#3D73F5] cursor-pointer p-2.5 pl-5 pr-5 rounded-3xl hover:bg-[#4169FC] duration-300 text-white font-bold
}

#impress p{
    @apply lg:text-[18px] md:text-[15px] text-[12px] font-bold text-gray-800 w-full;
}

#impress h1, h2{
    @apply lg:text-[40px] md:text-[26px] text-[27px]  font-bold text-gray-800 ;
}

#impress h3{
    @apply lg:text-[37px] md:text-[23px] text-[21px]  font-bold text-gray-800 ;
}

.card-background{
    background: rgb(223,222,255);
    background: linear-gradient(90deg, rgba(223,222,255,1) 0%, rgba(254,254,254,1) 100%);
    width: 100%
}

.card-background-resource{
    background: rgb(223,222,255);
    width: 100%
}



.start{
    background-color: #0A192F;
}

.background{
    background-image: url("./assets/img/server3.webp");
    object-fit: cover;
    background-position: center; /* Zentriert das Bild */
    background-repeat: no-repeat; /* Verhindert das Wiederholen des Bildes */
    background-size: cover;
}

.background-2{
    background-color: #e7eff9!important;
    background-image: linear-gradient(315deg, #e7eff9 0%, #cfd6e6 74%)!important;
}



.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: rgb(223,222,255);

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.mySwiper{
    gap: 7rem;
}
.mySwiper2{
    gap: 7rem;
}


.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.background-reseller{
    background-image: url('./assets/img/resellerBack.webp');
    background-position: center;
}

.background-productPort{
    background-image: url('./assets/img/productPortHeader.webp');
    background-position: center;
    background-size: cover;
}

.background-technic{
    background-image: url('./assets/img/technicMain.webp');
    background-position: center;
    background-size: cover;
}

.markdown-custom ul{
    list-style: disc;
    padding: 10px;
    margin: 10px;
}

.markdown-custom h1, h2, h3, h4, h5, h6{
    font-size: revert;
    font-weight: unset;
}

th, td {
    width: 120px;
    text-align: center;
    padding: 2px 3px;
    white-space: nowrap;
}

.swagger-ui th, td {
    width: 120px;
    text-align: left!important;
    padding: 2px 3px;
    white-space: nowrap;
}





tr:last-child td {
    border-bottom: none;
}

/* Optional: Falls Sie die Ränder zwischen den Zellen entfernen möchten */
table {
    border-collapse: collapse;
}

.scheme-container{
    background: none!important;
}

.checkbox-black:checked + .checkmark:after,
.checkbox-black:checked:focus + .checkmark:after {
    color: black !important;
}

.card{
    background: linear-gradient(134.81deg,hsla(0,0%,84%,.3) -2%,hsla(0,0%,83%,0) 100.78%),#fff;
}

.custom-block{
    width: 100%;
    position: absolute;
    bottom: 0px;
    top: 0px;
    box-shadow: inset -10px -10px 10px 20px white;
}